import React from 'react';
import { Link } from 'gatsby';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpen, faPhone, faFlag } from '@fortawesome/free-solid-svg-icons';
import './footer.scss';
import moment from 'moment';

export default function Footer() {
    const sinceYear = moment([1990, 1, 1]).locale("fr").fromNow(true)

    return (
        <div id="footer">
            <section>
                <div className="container flex_wrap_around">
                    {/* <div className="bloc">
                        <h3 className="after_title">Garage Avocat</h3>
                        <p>Depuis plus de {sinceYear}, un garage familial de proximité, avec une équipe de professionnels à votre service pour l'entretien et la réparation de vos véhicules, avec le souci de votre sécurité.</p>
                    </div> */}
                    <div className="bloc information">
                        <h3 className="after_title">Garage de Beaufort</h3>
                        <ul>
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faFlag} />
                                </div>
                                <div>
                                    <a href="https://goo.gl/maps/wsNE2UXun8AoTPsaA" target="_blank" rel="noreferrer">
                                        40 route de Domelin<br />
                                        73270 Beaufort
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                                <div>
                                    <a href="tel:+33479383324">
                                        04 79 38 33 24
                                    </a>
                                    <a href="tel:+33786122846">
                                        07 86 12 28 46
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                                </div>
                                <div className="link">
                                    <a href="mailto:joel.avocat@wanadoo.fr">
                                        joel.avocat@wanadoo.fr
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div>
                        </div>
                    </div>

                    <div className="bloc bloc_link">
                        <h3 className="after_title">Liens rapides</h3>
                        <Link to="/" className="lien_nav" activeStyle={{ color: "#ee2020" }}><span className="texte_lien_span">Accueil</span></Link>
                        <Link to="/nos-prestations" className="lien_nav" activeStyle={{ color: "#ee2020" }}><span className="texte_lien_span">Nos prestations</span></Link>
                        <Link to="/nos-garages" className="lien_nav" activeStyle={{ color: "#ee2020" }}><span className="texte_lien_span">Nos garages</span></Link>
                        <Link to="/contact" className="lien_nav" activeStyle={{ color: "#ee2020" }}><span className="texte_lien_span">Contact</span></Link>
                    </div>

                    <div className="bloc information">
                        <h3 className="after_title">Garage des Saisies</h3>
                        <ul>
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faFlag} />
                                </div>
                                <div>
                                    <a href="https://goo.gl/maps/rSh4Kscu5B6cAmdbA" target="_blank" rel="noreferrer">
                                        Route du Mont Bisanne<br />
                                        73620 Hauteluce
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                                <div>
                                    <a href="tel:+33479399034">
                                        04 79 39 90 34
                                    </a>
                                    <a href="tel:+33786122846">
                                        07 86 12 28 46
                                    </a>
                                </div>
                            </li>
                            <li>
                                <div className="icon">
                                    <FontAwesomeIcon icon={faEnvelopeOpen} />
                                </div>
                                <div className="link">
                                    <a href="mailto:joel.avocat@wanadoo.fr">
                                        joel.avocat@wanadoo.fr
                                    </a>
                                    <a href="mailto:avocat.carrosserie@orange.fr">
                                        avocat.carrosserie@orange.fr
                                    </a>
                                </div>
                            </li>
                        </ul>
                        <div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="copyright">
                <p>© 2021 Garage Avocat - <Link to="/mentions-legales" className="lien_nav" activeStyle={{ color: "#ee2020" }}><span className="texte_lien_span">Mentions Légales</span></Link ></p>
            </div>
        </div>
    )

}