var prevScrollpos = window.pageYOffset;
window.onscroll = function() {

var currentScrollpos = window.pageYOffset;
var navBar = document.getElementById("navbar");
if(prevScrollpos < 500) {
        navBar.classList.remove('top_100');
        navBar.classList.add('top_0');
    } else if (prevScrollpos > currentScrollpos) {
        navBar.classList.remove('top_100');
        navBar.classList.add('top_0');
    } else {
        navBar.classList.remove('top_0');
        navBar.classList.add('top_100');
    }
    prevScrollpos = currentScrollpos;
}