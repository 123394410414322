import React from "react"
import Footer from "./footer"
import Nav from './nav'
import { motion } from "framer-motion";

const Layout = ({ children }) => {

  const list = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1
    }
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={list}
      transition={{ ease: "easeOut", duration: .5 }}
    >
      <Nav />
      <main>{children}</main>
      <Footer />
    </motion.div>
  )
}

export default Layout