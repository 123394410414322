import React, { useState } from 'react'
import { Link } from 'gatsby'
import './nav-sticky'
import './nav.scss'

export default function Nav() {
    const [showMenu, setShowMenu] = useState('no_active')

    function handleClick() {
        if (showMenu === 'no_active') {
            setShowMenu('active')
        } else {
            setShowMenu('no_active')
        }
    }

    return (
        <div>
            <div id="nav_button" className={showMenu} onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex="0">
                <div className="nav_spans">
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
            <nav id="navbar" className={showMenu} aria-label="Handle Click" onClick={handleClick} onKeyDown={handleClick} role="button" tabIndex="0">
                <div className="div_navbar container">
                    <div className="menu_right">
                        <div className="links">
                            <ul className="navigation">
                                <li>
                                    <Link to="/" activeStyle={{ color: "#d11e1e" }}>
                                        <span className="texte_lien_span">Accueil</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/nos-prestations" activeStyle={{ color: "#d11e1e" }}>
                                        <span className="texte_lien_span">Nos prestations</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/nos-garages" activeStyle={{ color: "#d11e1e" }}>
                                        <span className="texte_lien_span">Nos garages</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/contact" activeStyle={{ color: "#d11e1e" }}>
                                        <span className="texte_lien_span">Contact</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    )
}